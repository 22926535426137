<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="font-weight-bold">アクセス</h1>
      </v-col>
    </v-row>

    <v-layout column wrap align-center>
      <p>〒８４７－１４４２</p>
      <p>佐賀県東松浦郡玄海町値賀川内１２１５−１</p>
      <iframe
        id="map-iframe"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.0224019423667!2d129.8664875152008!3d33.50079428075961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356a7a42d13c2e3d%3A0x69debf6184605934!2z44CSODQ3LTE0NDIg5L2Q6LOA55yM5p2x5p2-5rWm6YOh546E5rW355S65YCk6LOA5bed5YaF77yR77yS77yR77yV4oiS77yR!5e0!3m2!1sja!2sjp!4v1620198329082!5m2!1sja!2sjp"
        width="90%"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      >
      </iframe>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Access",
  mounted() {
    document.title = "徳永工務店 | アクセス";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "事務所の住所は佐賀県東松浦郡玄海町値賀川内１２１５−１"
      );
  },
};
</script>

<style scoped>
#container {
  text-align: center;
}
#map-iframe {
  height: 48vh;
}
</style>
